import React, { memo, useEffect, useRef } from 'react';
import { Icon } from '../customIcons';
import { useAppContext } from '../../context/AppContext';
import { useFormContext } from '../../context/FormContext';
import './styles.css';
import './styles';

function Answer({
  text,
  image,
  onClick,
  isActive,
  min,
  answersCount,
  hideTitle,
  dataGtm,
}) {
  const answerText = useRef(text);
  const { currentQuestionId } = useAppContext();
  const { formId } = useFormContext();

  useEffect(() => {
    const indexOfSlash = answerText.current.indexOf('/');
    if (indexOfSlash > -1) {
      answerText.current = (
        <>
          {answerText.current.substring(0, indexOfSlash + 1)}
          <wbr />
          {answerText.current.substring(indexOfSlash + 1, answerText.current.length)}
        </>
      );
    }
  }, []);

  return (
    <div
      className={[
        'answer-wrap',
        isActive ? 'active' : null,
        min ? 'min' : null,
        answersCount > 12 ? 'many-answers' : '',
        hideTitle ? 'hidden-title' : '',
      ]
        .filter((x) => x)
        .join(' ')}
      onClick={onClick}
      data-gtm={`${formId}::${currentQuestionId}::${text}`}
    >
      {!hideTitle ? (
        <div className="answer-text-wrap">
          <p className="answer-text">{answerText.current}</p>
        </div>
      ) : (
        false
      )}
      <div className="image-wrap">
        <Icon title={image} color="#1f282c" name={text} />
      </div>
    </div>
  );
}

export default memo(Answer);
