import React, { forwardRef, memo } from 'react';
import './styles.css';

const PrimaryButton = forwardRef(({ children, ...props }, ref) => {
  return (
    <a ref={ref} className="primary-button" {...props}>
      {children}
    </a>
  );
});

export default memo(PrimaryButton);
