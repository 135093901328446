import React, { memo, useCallback, useEffect, useState } from 'react';
import { IconArrowLeft } from '../../components/customIcons';
import { useAppContext } from '../../context/AppContext';
import { getLocalStorage, useFormContext } from '../../context/FormContext';
import api from '../../services/api';
import logger from '../../utils/logger';
import HeaderWithContent from '../HeaderWithContent';
import Loading from '../Loading';
import './styles.css';

function Ingredients({ question }) {
  const {
    handleGoPrev,
    ingredients,
    questions,
    setIngredients,
    notify,
    setAnswer,
    currentQuestionId,
  } = useAppContext();
  const [loading, setLoading] = useState(false);
  const { formId, creationToken } = useFormContext();

  const [userName, setUserName] = useState('');
  const [formulaName, setFormulaName] = useState('');

  const getAnswerText = useCallback(
    (questionId, answerId) => {
      const answers = questions.find((question) => question.id === questionId).answers;

      if (Array.isArray(answerId)) {
        return answerId
          .map((item) => answers.find((answer) => answer.id === item).text.trim())
          .join('|');
      }

      return answers.find((answer) => answer.id === answerId).text;
    },
    [questions]
  );

  useEffect(() => {
    setUserName(
      getLocalStorage(true)
        .answers.find(
          (answer) => answer.type === 'user_contact_info' || answer.type === 'user_name'
        )
        .answer.name.split(' ')[0]
    );

    setFormulaName(
      getLocalStorage(true).answers.find((answer) => answer.type === 'formula_name')
        .answer
    );
  }, []);

  useEffect(
    () => {
      const patchAndGetIngredients = () => {
        let localStorageData = getLocalStorage(true);
        let localStorageCheckoutData = getLocalStorage(false);
        setLoading(true);

        const patchBody = { creationToken };

        patchBody['questions'] = localStorageData.answers
          .filter((item) => item.type === 'single' || item.type === 'multi')
          .map((item) => ({
            id: item.id,
            answer: getAnswerText(item.id, item.answer),
            type: item.type,
          }));

        patchBody['email'] = localStorageData.answers
          .find(
            (answer) =>
              answer.type === 'user_contact_info' || answer.type === 'user_email'
          )
          .answer.email.toLowerCase();

        patchBody['name'] = localStorageData.answers.find(
          (answer) => answer.type === 'user_contact_info' || answer.type === 'user_name'
        ).answer.name;

        patchBody['phone'] = localStorageData.answers.find(
          (answer) => answer.type === 'user_contact_info' || answer.type === 'user_email'
        ).answer.phone;

        patchBody['formulaName'] = localStorageData.answers.find(
          (answer) => answer.type === 'formula_name'
        ).answer;

        patchBody['getIngredients'] = true;

        const urlSearchParams = new URLSearchParams(window.location.search);
        patchBody['utms'] = {
          source: urlSearchParams.get('utm_source') || '',
          medium: urlSearchParams.get('utm_medium') || '',
          campaign: urlSearchParams.get('utm_campaign') || '',
          term: urlSearchParams.get('utm_term') || '',
          content: urlSearchParams.get('utm_content') || '',
        };

        // If it is a hash buy, get hash from localStorage and send to back
        const hash = localStorageCheckoutData?.checkout?.hash;
        patchBody['hash'] = hash;

        api
          .patch(`/form/submissions/${formId}`, patchBody)
          .then((response) => {
            setIngredients(response.data.ingredients);
            setAnswer(currentQuestionId, response.data.ingredients);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            handleGoPrev();
            notify('Ops! Ocorreu um erro, aguarde e tente novamente.');

            const errorMessage = [];
            errorMessage.push('*Form:* ' + formId);
            errorMessage.push('*Agent:* ' + navigator.userAgent);

            if (!!error.isAxiosError && !error.response) {
              errorMessage.push('*patchAndGetIngredients:* no internet connection!');
            } else if (error.response) {
              errorMessage.push('*patchAndGetIngredients:* ' + error.response.status);
              errorMessage.push('*Message:* ' + error.response.data?.message);
            } else if (error.request) {
              errorMessage.push('*patchAndGetIngredients:* No response received!');
              errorMessage.push('*Timeout:*' + error.request.timeout);
              errorMessage.push('\n' + error.toString());
            } else {
              errorMessage.push('*patchAndGetIngredients:* ' + error);
            }

            logger.error(errorMessage.join('\n'));
          });
      };

      if (ingredients.length === 0) {
        const localFormData = getLocalStorage(true);
        const ingredientAnswerIndex = localFormData.answers.findIndex(
          (item) => item.type === 'ingredients'
        );
        if (localFormData.answers[ingredientAnswerIndex].answer === null) {
          patchAndGetIngredients();
        } else {
          setIngredients(localFormData.answers[ingredientAnswerIndex].answer);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      creationToken,
      formId,
      getAnswerText,
      ingredients.length,
      notify,
      setIngredients,
      setLoading,
    ]
  );

  return (
    <>
      <HeaderWithContent id="ingredients-header" />
      <div className="ingredients-header-wrap">
        <button className="hide-on-mobile" onClick={handleGoPrev}>
          <IconArrowLeft title="Voltar" color="#90969A" size={{ height: 20 }} />
        </button>
        <div className="ingredients-text-content">
          <h1 className="ingredients-header">
            {question.title.split('$FORMULA_NAME!', 2)[0]}
            <span className="user-name">{formulaName}</span>
            {question.title.split('$FORMULA_NAME!', 2)[1]}
          </h1>
          <h1 className="ingredients-sub">
            {question.subtitle.replace('$USER_NAME!', '')}
            <span className="user-name">{userName}</span>!
          </h1>
        </div>
      </div>
      <div className="ingredients-wrap">
        <ul className="ingredients-content">
          {ingredients.map((ingredient) => (
            <li key={ingredient.id} className="ingredients-item">
              <img
                src={`/images/ingredients/${ingredient.id}.svg`}
                alt={ingredient.title}
              />
              <h2>{ingredient.title}</h2>
              <p>{ingredient.text}</p>
            </li>
          ))}
        </ul>
      </div>

      {loading && <Loading />}
    </>
  );
}

export default memo(Ingredients);
