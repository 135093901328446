import React, { useRef, useEffect } from 'react';
import { IconCloseSquareOutline } from '../customIcons';
import './styles.css';
import './styles';

function Modal({ onClose, show, children, className, bodyClassName }) {
  const modalWrap = useRef();
  const modalBody = useRef();

  useEffect(() => {
    if (show) {
      modalWrap.current.addEventListener('mousedown', (e) => {
        e.stopPropagation();
        if (!modalBody.current.contains(e.target)) {
          onClose();
        }
      });
    }
  },[onClose, show]);

  if (!show) {
    return null;
  }

  return (
    <div className="modal-wrap" ref={modalWrap}>
      <div className={['modal-body', bodyClassName].join(' ')} ref={modalBody}>
        <header>
          <button onClick={onClose}>
            <IconCloseSquareOutline
              color="#8c8c8c"
              size={{ width: 28 }}
              title="Fechar Popup"
              className="icon-close-modal"
            />
          </button>
        </header>

        <div className={['modal-content', className].join(' ')}>{children}</div>
      </div>
    </div>
  );
}

export default Modal;
