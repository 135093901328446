import React, { useCallback } from 'react';
import { useAppContext } from '../../context/AppContext';
import './styles.css';

function GiftMessage({ question }) {
  const { setAnswer, answers } = useAppContext();

  const handleChange = useCallback(
    (field, value) => {
      setAnswer(question.id, {
        ...(answers.find(item => item.id === question.id).answer || {}),
        [field]: value,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [answers, question.id],
  );

  return (
    <div className="gift-step3-wrap">
      <textarea
        name=""
        id=""
        rows="15"
        placeholder="Digite seu recado aqui..."
        onChange={e => handleChange('giftMessage', e.target.value)}
        value={
          answers.find(item => item.id === question.id).answer?.giftMessage ||
          ''
        }
      ></textarea>
    </div>
  );
}

export default GiftMessage;
