import React, { memo } from 'react';
import { IconArrowLeft, IconLogoJust } from '../customIcons';
import { useAppContext } from '../../context/AppContext';
import './styles.css';

function Header({ text }) {
  const { hasPrev, handleGoPrev } = useAppContext();

  return (
    <div className="header-wrap">
      {hasPrev() && (
        <button className="hide-on-desktop" onClick={handleGoPrev} aria-label="Questão Anterior">
          <IconArrowLeft title="Voltar" color="#90969A" size={{height: 20}} />
        </button>
      )}
      <IconLogoJust color="#EC8063" size={{width: 115}} title="Just For You Logo"/>
      {text && <p>{text}</p>}
    </div>
  );
}

export default memo(Header);
