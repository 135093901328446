import { useEffect } from 'react';
import { CHECKOUT_URL } from '../../constants';
import { clearLocalStorage, setLocalStorage } from '../../context/FormContext';

function NewForm() {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const cartId = urlSearchParams.get('cartId');
    const campaignHash = urlSearchParams.get('cpmhash');
    if (cartId) {
      // if URL has cartId as a parameter, it was sent from checkout in a multiple forms
      // case. The correct handling is to ensure localStorage is empty, add checkout
      // data to it and clear the cartId from URL to not contaminate site's tracking
      setLocalStorage({
        checkout: {
          url: `${CHECKOUT_URL}/precheckout/${cartId}/edit`,
          cartId,
        },
      });
      urlSearchParams.delete('cartId');
    } else if (campaignHash) {
      clearLocalStorage();
      // remove cpmhash from url
      urlSearchParams.delete('cpmhash');
      setLocalStorage({
        checkout: {
          hash: campaignHash,
        },
      });
    } else {
      clearLocalStorage();
    }
    setTimeout(() => {
      window.location.href =
        '/' + (urlSearchParams.toString() !== '' ? '?' + urlSearchParams.toString() : '');
    }, 600);
  });

  return null;
}

export default NewForm;
