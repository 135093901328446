import amplitude from 'amplitude-js';
import googleTagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { APP_VERSION } from './constants';
import tracking from './services/tracking';

/**
 * Only track in production and if user let it.
 */
export const startTracking = () => {
  sessionStorage.setItem('trackingEnabled', isTrackingEnabled().toString());
  initTracking();
  trackFirstPageView();
};

export const isTrackingEnabled = () => {
  if (typeof window === 'undefined') return true;

  if (process.env.NODE_ENV !== 'production') return false;

  const external = window?.external;
  const msTracking =
    typeof external !== 'undefined' &&
    'msTrackingProtectionEnabled' in external &&
    typeof external.msTrackingProtectionEnabled === 'function' &&
    window.external.msTrackingProtectionEnabled();

  const navigator = window?.navigator;

  const dnt = window?.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack || msTracking;

  if (!dnt) return true;

  if (dnt === true || dnt === 1 || dnt === 'yes' || (typeof dnt === 'string' && dnt.charAt(0) === '1')) {
    console.log('[Tracking] Do not track found, trackers disabled.');
    return false;
  }

  return true;
};

export const initTracking = () => {
  if (sessionStorage.getItem('trackingEnabled') === 'false') return;

  googleTagManager.initialize({ gtmId: process.env.REACT_APP_TAGMANAGER_ID });

  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY);
  amplitude.getInstance().setVersionName(APP_VERSION);

  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);
};

export const sendGtmEvent = (eventName, payload = {}) => {
  if (sessionStorage.getItem('trackingEnabled') === 'false') return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: eventName, ...payload });

  console.log(`[Tracking] GTM ${eventName} sent!`);
};

export const sendAmplitudeEvent = (eventName, startTime, payload = {}) => {
  if (sessionStorage.getItem('trackingEnabled') === 'false') return;

  if (startTime) {
    amplitude.getInstance().logEventWithGroups(eventName, {
      ...payload,
      time: Date.now() - startTime,
    });
  } else {
    amplitude.getInstance().logEventWithGroups(eventName, { ...payload });
  }

  console.log(`[Tracking] Amplitude ${eventName} sent!`);
};

export const trackFirstPageView = () => {
  if (sessionStorage.getItem('trackingEnabled') === 'false') return;

  const urlParams = new URLSearchParams(window.location.search);
  amplitude.getInstance().logEvent('formAccess', {
    utm_source: urlParams.get('utm_source'),
    utm_medium: urlParams.get('utm_medium'),
    utm_campaign: urlParams.get('utm_campaign'),
    utm_content: urlParams.get('utm_content'),
    utm_term: urlParams.get('utm_term'),
  });

  console.log('[Tracking] Amplitude formAccess sent!');
};


export const sendFacebookEvent = (
  eventName,
  eventId,
  externalId,
  userData = {}
) => {

  const nameSplitted = userData?.name?.split(' ');
  const firstName = nameSplitted ? nameSplitted[0] : '';
  const lastName =
    nameSplitted && nameSplitted.length > 1
      ? nameSplitted[nameSplitted.length - 1]
      : '';
  const cellPhone = userData?.phone
    ? '55' + userData.phone.replace(/[() -]/g, '')
    : '';

  const track_payload = {
    event_name: eventName,
    event_id: eventId,
    external_id: externalId,
    user_email: userData?.email,
    user_first_name: firstName,
    user_last_name: lastName,
    user_phone: cellPhone,
  };
  tracking.post("/event/facebook", track_payload).catch((err) => {
    console.error(err);
  });

};
