import React, { memo, useEffect, useState } from 'react';
import './styles.css';

function IconButton({ onClick, pageTime, children, ...rest }) {
  const [pulse, setPulse] = useState(false);

  useEffect(() => {
    setPulse(false);
    const interval = setInterval(() => {
      if (Date.now() - pageTime > 20000) {
        setPulse(true);
        clearInterval(interval);
      }
    }, 2000);
  }, [pageTime]);
  
  return (
    <button
      className={['icon-button', pulse ? 'pulse' : ''].join(' ')}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}

export default memo(IconButton);
