import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { sendAmplitudeEvent, sendFacebookEvent, sendGtmEvent } from '../analytics';
import { IconExclamationTriangle } from '../components/customIcons';
import { REGEX_EMAIL_VALIDATOR, REGEX_PHONE_VALIDATOR } from '../constants';
import { hash } from '../utils/hash';
import { getLocalStorage, setLocalStorage } from './FormContext';

const AppContext = createContext(null);

export function AppProvider(props) {
  const [formId, setFormId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [formulaName, setFormulaName] = useState('');
  const [lastIndex, setLastIndex] = useState(null);

  const toastId = useRef(null);

  const loadLastLocalForm = useCallback(() => {
    if (questions.length !== 0) return;
    // Check if we already have an ongoing form and continue from it
    const localStorageData = getLocalStorage(true);

    // Test formId because can have a form but is corrupted (I dont know a case but maybe...)
    if (localStorageData?.formId) {
      console.log('[JUST] Form loaded from local storage:', localStorageData.formId);

      setFormId(localStorageData.formId);
      setAnswers(localStorageData.answers);
      setQuestions(localStorageData.questions);

      let lastAnsweredQuestion;
      const formCompleted = localStorageData.answers.every((answer) => {
        if (answer.answer === null) return false;

        if (Array.isArray(answer.answer) && answer.answer.length === 0) {
          return false;
        }

        lastAnsweredQuestion = answer.id;
        return true;
      });

      if (formCompleted) {
        let lastQuestion =
          localStorageData.questions[localStorageData.questions.length - 1];
        setLastIndex(questions.findIndex((question) => question.id === lastQuestion.id));
        setCurrentQuestionId(lastQuestion.id);
      } else if (lastAnsweredQuestion) {
        setCurrentQuestionId(lastAnsweredQuestion);
      } else {
        setCurrentQuestionId(localStorageData.questions[0].id);
        setIngredients([]);
      }
    } else {
      console.log('[JUST] There is no form on local storage.');
    }
  }, [questions]);

  useEffect(loadLastLocalForm, [loadLastLocalForm]);

  useEffect(() => {
    if (currentQuestionId) {
      setStartTime(Date.now());
    }
  }, [currentQuestionId, questions]);

  const Message = ({ message }) => (
    <div className="toast-message-wrap">
      <IconExclamationTriangle color="#fff" size={40} />
      <p>{message}</p>
    </div>
  );

  const notify = useCallback((message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.warn(<Message message={message} />);
    }
  }, []);

  const currQuestionIndex = useCallback(() => {
    return questions.findIndex((question) => question.id === currentQuestionId);
  }, [currentQuestionId, questions]);

  const hasPrev = useCallback(() => {
    return currQuestionIndex() > 0;
  }, [currQuestionIndex]);

  const hasNext = useCallback(() => {
    return currQuestionIndex() < questions.length - 1;
  }, [currQuestionIndex, questions]);

  const handleGoPrev = useCallback(() => {
    sendAmplitudeEvent('prev', startTime, {
      from: questions[currQuestionIndex()].id,
      to: questions[currQuestionIndex() - 1].id,
    });

    const skipConfig = questions[currQuestionIndex() - 1].skippable;
    let prevQuestionId = currQuestionIndex() - 1;

    if (skipConfig) {
      const userAnwserToInfluenceQuestion = answers.find(
        (answer) => answer.id === skipConfig.influenceQuestion
      ).answer;

      const checkUserAnswer = (userAnswer, skipConfigAnswer) => {
        if (!Array.isArray(userAnswer)) {
          return userAnswer === skipConfigAnswer;
        } else if (skipConfig.exactly) {
          return skipConfigAnswer.every((answer) => userAnswer.includes(answer));
        } else {
          return skipConfigAnswer.some((answer) => userAnswer.includes(answer));
        }
      };

      if (checkUserAnswer(userAnwserToInfluenceQuestion, skipConfig.influenceAnswer)) {
        setAnswer(questions[prevQuestionId].id, null);
        prevQuestionId--;
      }
    }

    sendAmplitudeEvent('prev', startTime, {
      from: questions[currQuestionIndex()].id,
      to: questions[prevQuestionId].id,
    });

    setLastIndex(currQuestionIndex());
    setCurrentQuestionId(questions[prevQuestionId].id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, questions, currQuestionIndex, answers]);

  const handleGoNext = useCallback(() => {
    if (currQuestionIndex() === questions.length - 1) return;

    const currentAnswer = answers.find((item) => item.id === currentQuestionId).answer;
    const currentQuestion = questions.find(
      (question) => question.id === currentQuestionId
    );

    if (currentQuestion.isRequired === false) {
      sendAmplitudeEvent('next', startTime, {
        from: questions[currQuestionIndex()].id,
        to: questions[currQuestionIndex() + 1].id,
      });
      setLastIndex(currQuestionIndex());
      setCurrentQuestionId(questions[currQuestionIndex() + 1].id);
      toast.dismiss();
      return;
    }

    if (
      currentQuestion.type === 'user_email' ||
      currentQuestion.type === 'user_contact_info'
    ) {
      const emailRegex = new RegExp(REGEX_EMAIL_VALIDATOR);
      if (!currentAnswer?.name) {
        notify('Ops, você precisa digitar o nome antes de prosseguir!');
        return;
      }
      if (!emailRegex.test(currentAnswer?.email)) {
        notify(
          'Ops, você precisa digitar um e-mail válido, sem acentos, antes de prosseguir!'
        );
        return;
      }

      const phoneRegex = new RegExp(REGEX_PHONE_VALIDATOR);
      if ((currentAnswer?.phone?.length || 0) > 0) {
        if (!phoneRegex.test(currentAnswer?.phone)) {
          notify('Ops, você precisa digitar um telefone válido antes de prosseguir!');
          return;
        }
      }
    }

    if (
      Array.isArray(currentAnswer) &&
      currentQuestion.min &&
      currentAnswer.length < currentQuestion.min
    ) {
      notify(`Ops, selecione ${currentQuestion.min} efeitos antes de prosseguir!`);
      return;
    }

    if (!(Array.isArray(currentAnswer) ? currentAnswer.length : currentAnswer)) {
      notify('Ops, você precisa responder antes de prosseguir!');
      return;
    }

    if (currentQuestion?.type === 'user_contact_info') {
      sendGtmEvent('formUserData', {
        fRqm_x: currentAnswer.email?.split('@')[0],
        fRqm_p: currentAnswer.email?.split('@')[1],
        bPg55H: currentAnswer.name?.trim(),
        k9aM24: currentAnswer.phone?.replace(/[() -]/g, ''),
        hashedEmail: hash(currentAnswer.email),
      });

      if (!sessionStorage.getItem('conversionEventSent')) {
        const eventId = `lead-${formId}`;
        const externalId = currentAnswer?.email;

        sendGtmEvent('completedForm', { event_id: eventId, external_id: externalId });
        sendFacebookEvent('Lead', eventId, '', currentAnswer);
        sessionStorage.setItem('conversionEventSent', 'true');
      }
    }

    const skipConfig = questions[currQuestionIndex() + 1].skippable;
    let nextQuestionId = currQuestionIndex() + 1;

    if (skipConfig) {
      const userAnwserToInfluenceQuestion = answers.find(
        (answer) => answer.id === skipConfig.influenceQuestion
      ).answer;

      const checkUserAnswer = (userAnswer, skipConfigAnswer) => {
        if (!Array.isArray(userAnswer)) {
          return userAnswer === skipConfigAnswer;
        } else if (skipConfig.exactly) {
          return skipConfigAnswer.every((answer) => userAnswer.includes(answer));
        } else {
          return skipConfigAnswer.some((answer) => userAnswer.includes(answer));
        }
      };

      if (checkUserAnswer(userAnwserToInfluenceQuestion, skipConfig.influenceAnswer)) {
        setAnswer(questions[nextQuestionId].id, skipConfig.defaultAnswer);
        nextQuestionId++;
      }
    }

    sendAmplitudeEvent('next', startTime, {
      from: questions[currQuestionIndex()].id,
      to: questions[nextQuestionId].id,
    });

    setLastIndex(currQuestionIndex());
    setCurrentQuestionId(questions[nextQuestionId].id);

    toast.dismiss();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currQuestionIndex, questions, answers, startTime, currentQuestionId, notify]);

  function setAnswer(questionId, answerId) {
    const question = answers.find((item) => item.id === questionId);

    if (question) {
      const newAnswers = answers.map((item) =>
        item.id === questionId ? { ...item, answer: answerId } : item
      );
      setAnswers(newAnswers);

      // Clone last local form, add new answers and edit local storage
      const localStorageData = getLocalStorage();

      if (!localStorageData) {
        const urlToRedirect = new URL(window.location.origin + '/new');
        window.location.href = urlToRedirect.href;
      }

      setLocalStorage({
        ...localStorageData,
        form: { ...localStorageData.form, answers: newAnswers },
      });

      return;
    }
  }

  function handleAnswerSingleChoice(questionId, answerId) {
    const editedQuestions = [...questions];
    const index = editedQuestions.findIndex((q) => q.id === questionId);

    // For single choice questions, make sure that only the answer selected by user
    // is marked as selected
    editedQuestions[index].answer.singleChoiceAnswers.forEach((a) => {
      a.isSelected = a.id === answerId;
    });

    setQuestions(editedQuestions);
    // Move to next question when a single answer is selected
    handleGoNext();
  }

  function handleAnswerMultipleChoice(questionId, answerId) {
    const editedQuestions = [...questions];
    const index = editedQuestions.findIndex((q) => q.id === questionId);

    // For multiple choice questions, just toggle whatever the user selected
    editedQuestions[index].answer.multiChoiceAnswers.forEach((a) => {
      if (a.id === answerId) {
        a.isSelected = !a.isSelected;
      }
    });

    setQuestions(editedQuestions);
  }

  function handleAnswerText(questionId, answerValue) {
    const editedQuestions = [...questions];
    const index = editedQuestions.findIndex((q) => q.id === questionId);

    editedQuestions[index].answer.textAnswer.answerValue = answerValue;
    setQuestions(editedQuestions);
  }

  const context = {
    hasPrev,
    hasNext,
    handleGoPrev,
    handleGoNext,
    handleAnswerSingleChoice,
    handleAnswerMultipleChoice,
    handleAnswerText,
    setCurrentQuestionId,
    setFormId,
    questions,
    currentQuestionId,
    formId,
    setQuestions,
    setAnswer,
    answers,
    notify,
    ingredients,
    setIngredients,
    formulaName,
    setFormulaName,
    startTime,
    lastIndex,
    loadLastLocalForm,
  };

  return <AppContext.Provider value={context} {...props} />;
}

export function useAppContext() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
}
