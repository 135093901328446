import React, { useCallback } from 'react';
import { useAppContext } from '../../context/AppContext';
import { useFormContext } from '../../context/FormContext';
import Answer from '../Answer';
import './styles.css';

function SingleChoiceAnswer({ question }) {
  const { setAnswer, answers, currentQuestionId } = useAppContext();
  const { formId } = useFormContext();

  function isActive(id) {
    const answer = answers?.find((item) => item?.id === question?.id);

    if (answer) {
      return answer.answer === id;
    }

    return false;
  }

  const handleClick = useCallback(
    (id) => {
      setAnswer(question.id, id);
    },
    [setAnswer, question]
  );

  return (
    <div
      className={[
        'single-choice-answer-wrap',
        question?.answers.length > 4 ? 'desktop-many-question' : '',
      ].join(' ')}
    >
      {question?.answers.map(({ id, text, img }) => (
        <Answer
          key={id}
          text={text}
          image={img}
          isActive={isActive(id)}
          onClick={() => handleClick(id)}
          min={question.answers.length > 4}
          answersCount={question.answers.length}
          hideTitle={question?.hideTitle}
          dataGtm={`${formId}::${currentQuestionId}::${text}`}
        />
      ))}
    </div>
  );
}

export default SingleChoiceAnswer;
