import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AppProvider } from './context/AppContext';
import { FormProvider } from './context/FormContext';
import {checkCampaign, checkExistentCart} from './utils/beforeLoad'
import Routes from './routes';
import 'react-toastify/dist/ReactToastify.css';
import './css/app.css';

checkCampaign()
checkExistentCart()

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <FormProvider>
          <Routes />
          <ToastContainer
            className="toaster-wrap"
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
          />
        </FormProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
