export const giftQuestions = [
  {
    id: 20,
    isRequired: true,
    title: 'Defina o nome da sua fórmula',
    description: {
      title: 'Dê um nome bem especial à sua fórmula!',
      description: [
        {
          title: '',
          text: 'Essa é a pitada final para sua fórmula ficar ainda mais especial! Você pode dar o seu nome ou criar um que deseje!',
          img: '',
        },
      ],
    },
    type: 'user_name',
  },
  {
    id: 21,
    isRequired: true,
    title: 'Escreva uma mensagem especial:',
    description: {
      title: '',
      description: [
        {
          title: 'Informe seu melhor email',
          text: 'Assim conseguimos te informar sobre toda a jornada do seu kit, até sua aguardada chegada!',
          img: '',
        },

        {
          title: 'Descontos exclusivos no whatsapp',
          text: 'Para ficar sempre por dentro de todas as novidades, lançamentos de produtos e dicas incríveis sobre autocuidado, nos informe seu whatsapp.',
          img: '',
        },
      ],
    },
    type: 'gift_message',
  },
  {
    id: 22,
    isRequired: true,
    title: '',
    description: {
      title: '',
      description: [
        {
          title: 'Informe seu melhor email',
          text: 'Assim conseguimos te informar sobre toda a jornada do seu kit, até sua aguardada chegada!',
          img: '',
        },

        {
          title: 'Descontos exclusivos no whatsapp',
          text: 'Para ficar sempre por dentro de todas as novidades, lançamentos de produtos e dicas incríveis sobre autocuidado, nos informe seu whatsapp.',
          img: '',
        },
      ],
    },
    type: 'user_email',
  },
];

export const giftEndQuestions = [
  {
    id: 31,
    isRequired: true,
    title: 'Defina o nome da sua fórmula',
    description: {
      title: 'Dê um nome bem especial à sua fórmula!',
      description: [
        {
          title: '',
          text: 'Essa é a pitada final para sua fórmula ficar ainda mais especial! Você pode dar o seu nome ou criar um que deseje!',
          img: '',
        },
      ],
    },
    type: 'formula_name',
  },

  {
    id: 33,
    isRequired: true,
    title: '',
    description: {
      title: '',
      description: [
        {
          title: 'Ativos',
          text: 'Assim conseguimos te informar sobre toda a jornada do seu kit, até sua aguardada chegada!',
          img: '',
        },

        {
          title: 'Descontos exclusivos no whatsapp',
          text: 'Para ficar sempre por dentro de todas as novidades, lançamentos de produtos e dicas incríveis sobre autocuidado, nos informe seu whatsapp.',
          img: '',
        },
      ],
    },
    type: 'ingredients',
  },

  {
    id: 34,
    isRequired: true,
    title: '',
    description: {
      title: '',
      description: [
        {
          title: 'Informe seu melhor email',
          text: 'Assim conseguimos te informar sobre toda a jornada do seu kit, até sua aguardada chegada!',
          img: '',
        },

        {
          title: 'Descontos exclusivos no whatsapp',
          text: 'Para ficar sempre por dentro de todas as novidades, lançamentos de produtos e dicas incríveis sobre autocuidado, nos informe seu whatsapp.',
          img: '',
        },
      ],
    },
    type: 'presentation',
  },
];

export const normalQuestions = [
  {
    id: 31,
    isRequired: true,
    title: 'Escolha o nome da sua fórmula',
    subtitle: 'Ele será impresso na embalagem!',
    description: {
      title: 'Entenda',
      description: [
        {
          title: '',
          text: 'Essa é a pitada final para sua fórmula ficar ainda mais especial! Você pode dar o seu nome, apelido ou criar um que deseje! Escreva em até 15 caracteres (passando disso, perceba que o teclado não responderá e a palavra pode sair impressa pela metade).',
          img: '',
        },
      ],
    },
    type: 'formula_name',
  },
  {
    id: 33,
    isRequired: true,
    title: 'Agora preencha para ver o resultado do seu teste!',
    description: {
      title: '',
      description: [
        {
          title: 'Informe seu melhor email',
          text: 'Assim conseguimos te informar sobre toda a jornada do seu kit, até sua aguardada chegada!',
          img: '',
        },

        {
          title: 'Descontos exclusivos no whatsapp',
          text: 'Para ficar sempre por dentro de todas as novidades e lançamentos de produtos nos informe seu whatsapp.',
          img: '',
        },
      ],
    },
    type: 'user_contact_info',
  },
  {
    id: 34,
    isRequired: false,
    title: 'Sua fórmula $FORMULA_NAME! foi criada com sucesso!',
    subtitle: 'Esses são os Ingredientes ideais para seu cabelo, $USER_NAME!',
    description: {
      title: '',
      description: [
        {
          title: 'Ativos',
          text: 'Os ativos são os óleos que ajudarão na melhor construção de seu cabelo!',
          img: '',
        },
      ],
    },
    type: 'ingredients',
  },
];
