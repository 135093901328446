const LOCAL_STORAGE_PREFIX = '@JustForYou';

const LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_PREFIX}:${
  process.env.REACT_APP_LOCAL_STORAGE_FORM_VERSION || 'Form3.0'
}`;

const CHECKOUT_URL =
  process.env.REACT_APP_CHECKOUT_URL || 'https://checkout.justfor.com.br';

// Regex created by combining all the common possibilities on the user's keyboard
// https://apps.timwhitlock.info/js/regex
// https://apps.timwhitlock.info/unicode/inspect
const REGEX_REMOVE_UNICODE_FORMULA = /[^A-Za-zÀ-ú0-9 ]+/g;

// For more details about this regex see https://regex101.com/r/9bDxHM/1
const REGEX_EMAIL_VALIDATOR =
  /((^[^<>()[\]\\.,;:\s@"À-ú]+(\.[^<>()[\]\\.,;:\s@"À-ú]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// old regex
//const REGEX_EMAIL_VALIDATOR = /^(([^<>()[\]\\.,;:\s@"¨]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const REGEX_PHONE_VALIDATOR = /\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4,4}$/;
const APP_VERSION = '3.3';

const CAMPAIGNS = {
  b4f18f5b05307bd1e3cc00e0802d641b: {
    products: process.env.REACT_APP_INVESTOR_PRODUCTS?.split(',') || [],
    promocode: 'QRCODE', // Inverstors
  },
  '93374e12fc53d963cd58ebb7c54ccdd9': {
    products: process.env.REACT_APP_MICRO_INFLUENCER_PRODUCTS?.split(',') || [],
    promocode: 'INFLUENCER', // Micro Influencers
  },
  '3fa9ffa3289fca074df4f46bd21fa1fb': {
    products: process.env.REACT_APP_INFLUENCER_PRODUCTS?.split(',') || [],
    promocode: 'INFLUENCER', // Influencers
  },
  '08858620fbfe80942a954bfe8a7fd137': {
    products: process.env.REACT_APP_INFLUENCER_2_PRODUCTS?.split(',') || [],
    promocode: 'INFLUENCER', // Influencers
  },
  adc40d3d750187b5db85e4e49a868144: {
    products: process.env.REACT_APP_INFLUENCER_3_PRODUCTS?.split(',') || [],
    promocode: 'INFLUENCER', // Influencers
  },
};

const BETA_CHECKOUT_PRODUCTS = {
  shampoo: '272U0XU9ON',
  conditioner: 'Q2GTJXP5YQ',
  leavein: '96GBI8KFKU',
  mask: 'J4ZIBKV2V8',
};

const JFY_CHECKOUT_PRODUCTS = {
  shampoo: { id: '25', price: 74.95 },
  conditioner: { id: '26', price: 74.95 },
  leavein: { id: '36', price: 59.9 },
  mask: { id: '39', price: 64.9 },
};

export {
  LOCAL_STORAGE_KEY,
  REGEX_REMOVE_UNICODE_FORMULA,
  REGEX_EMAIL_VALIDATOR,
  REGEX_PHONE_VALIDATOR,
  APP_VERSION,
  CAMPAIGNS,
  BETA_CHECKOUT_PRODUCTS,
  CHECKOUT_URL,
  JFY_CHECKOUT_PRODUCTS,
};
