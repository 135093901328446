/* eslint-disable no-unused-vars */
import React, { memo, useCallback, useEffect, useState } from 'react';
import AnswersProgress from '../../components/AnswersProgress';
import DescriptionModal from '../../components/DescriptionModal';
import FormulaName from '../../components/FormulaName';
import Header from '../../components/Header';
import IconButton from '../../components/IconButton';
import Ingredients from '../../components/Ingredients';
import MultiChoiceAnswers from '../../components/MultiChoiceAnswer';
import PrimaryButton from '../../components/PrimaryButton';
import SingleChoiceAnswer from '../../components/SingleChoiceAnswer';
import UserEmail from '../../components/UserEmail';
import { useAppContext } from '../../context/AppContext';
import { IconHelp, IconMessage } from '../../components/customIcons';
import { sendAmplitudeEvent } from '../../analytics';
import GiftUserName from './GiftUserName';
import GiftMessage from './GiftMessage';
import GiftUserEmail from './GiftUserEmail';
import './styles.css';

function Form() {
  const { currentQuestionId, questions, handleGoNext } = useAppContext();
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setCurrentQuestion(
      questions.find((question) => question.id === currentQuestionId) || questions[0]
    );
  }, [currentQuestionId, questions]);

  const handleCloseModal = useCallback(() => {
    sendAmplitudeEvent('closeModal', undefined, {
      question: currentQuestionId,
      modalTime: Date.now() - startTime,
    });
    setShowModal(false);
  }, [startTime, currentQuestionId]);

  const handleOpenModal = useCallback(() => {
    setStartTime(Date.now());
    sendAmplitudeEvent('openModal', undefined, { question: currentQuestionId });
    setShowModal(true);
  }, [currentQuestionId]);

  const getQuestion = useCallback(() => {
    return {
      user_name: <GiftUserName question={currentQuestion} />,
      gift_message: <GiftMessage question={currentQuestion} />,
      user_email: <GiftUserEmail question={currentQuestion} />,
      single: <SingleChoiceAnswer question={currentQuestion} />,
      multi: (
        <MultiChoiceAnswers
          question={currentQuestion}
          maxAnswers={currentQuestion?.max}
        />
      ),
      formula_name: (
        <FormulaName title={currentQuestion?.title} question={currentQuestion} />
      ),
      user_contact_info: <UserEmail question={currentQuestion} />,
      ingredients: <Ingredients />,
    }[currentQuestion?.type];
  }, [currentQuestion]);

  const answerWrapStyle =
    currentQuestion?.answers?.length < 5
      ? { display: 'flex', alignItems: 'center' }
      : null;

  return (
    <>
      <div className="form-wrap">
        <GiftUserEmail question={currentQuestion} />

        <PrimaryButton onClick={handleGoNext}>Próximo</PrimaryButton>
        <div className="form-actions">
          <IconButton onClick={handleOpenModal}>
            <IconHelp size={{ height: 26 }} color="#fff" />
          </IconButton>

          <AnswersProgress />

          <IconButton>
            <IconMessage size={{ height: 26 }} color="#fff" />
          </IconButton>
        </div>
      </div>

      {/* <div className="form-wrap">
        {[
          'formula_name',
          'user_contact_info',
          'ingredients',
          'user_name',
          'user_email',
        ].find(item => item === currentQuestion?.type) ? (
          getQuestion()
        ) : (
          <>
            <Header text={currentQuestion?.title} />
            <div className="answers-wrap" style={answerWrapStyle}>
              {getQuestion()}
            </div>
          </>
        )}

        <PrimaryButton
          style={{ height: '42px', marginTop: '16px' }}
          onClick={handleGoNext}
        >
          Próximo
        </PrimaryButton>
        <div className="form-actions">
          <IconButton onClick={handleOpenModal}>
            <IconHelp size={{height: 26}} color="#fff" />
          </IconButton>
          <AnswersProgress />
          <IconButton>
            <IconMessage size={{height: 26}} color="#fff" />
          </IconButton>
        </div>
      </div>
      <DescriptionModal
        show={showModal}
        onClose={handleCloseModal}
        description={currentQuestion?.description}
      /> */}
    </>
  );
}

export default memo(Form);
