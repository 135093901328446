import React, { memo, useMemo } from 'react';
import { animated, useTransition } from 'react-spring';
import { useAppContext } from '../../context/AppContext';
import Question from './Question';

import './styles.css';
import './styles';

function Form() {
  const { currentQuestionId, lastIndex, questions } = useAppContext();

  const index = questions.findIndex(
    question => question.id === currentQuestionId,
  );

  const transitions = useTransition(index === -1 ? 0 : index, (p) => p, {
    from: {
      opacity: 0,
      transform: lastIndex < index ? 'translate3d(100%,0,0)' : 'translate3d(0%,0,0)',
    },
    enter: {
      opacity: 1,
      transform: lastIndex < index ? 'translate3d(0,0,0)' : 'translate3d(0%,0,0)',
    },
    leave: {
      opacity: index === -1 ? 1 : 0,
      transform: lastIndex < index ? 'translate3d(0,0,0)' : 'translate3d(100%,0,0)',
      zIndex: lastIndex < index ? 0 : 10,
    },
    config: {
      duration: lastIndex < index ? 200 : 300,
    },
  });

  const pages = useMemo(() => {
    return questions.map(question => ({ style }) => (
      <animated.div style={{ ...style }} className="animated-div">
        <Question question={question} />
      </animated.div>
    ));
  }, [questions]);

  if (!pages.length) {
    return null;
  }

  return transitions.map(({ item, props, key }) => {
    const Page = pages[item];
    return <Page key={key} style={props} />;
  });
}

export default memo(Form);
