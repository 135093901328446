import React, { useCallback, useRef, useState } from 'react';
import logger from '../../utils/logger';
import AnswersProgress from '../../components/AnswersProgress';
import DescriptionModal from '../../components/DescriptionModal';
import FormulaName from '../../components/FormulaName';
import Header from '../../components/Header';
import IconButton from '../../components/IconButton';
import { IconArrowLeft } from '../../components/customIcons';
import Ingredients from '../../components/Ingredients';
import MultiChoiceAnswers from '../../components/MultiChoiceAnswer';
import PrimaryButton from '../../components/PrimaryButton';
import LoadingDots from '../../components/LoadingDots';
import SingleChoiceAnswer from '../../components/SingleChoiceAnswer';
import UserEmail from '../../components/UserEmail';
import { IconHelp, IconMessage } from '../../components/customIcons';
import { sendAmplitudeEvent } from '../../analytics';

import { useFormContext } from '../../context/FormContext';
import { useAppContext } from '../../context/AppContext';

function Question({ question }) {
  const [showModal, setShowModal] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const buttonNext = useRef(null);

  const {
    pageTime,
    hasPrev,
    handleGoPrev,
    hasNext,
    handleGoNext,
    currentQuestionId,
    notify,
  } = useAppContext();
  const { handleFinish, formId } = useFormContext();

  // It simply creates an object which stores different components/functions.
  // Then, it calls one of them according to the current value of question.type
  const getQuestion = useCallback((question) => {
    try {
      return {
        single: <SingleChoiceAnswer question={question} />,
        multi: <MultiChoiceAnswers question={question} maxAnswers={question?.max} />,
        formula_name: <FormulaName question={question} />,
        user_contact_info: <UserEmail question={question} />,
        ingredients: <Ingredients question={question} />,
      }[question.type];
    } catch (e) {
      const errorMessage = [];
      errorMessage.push('*Question:* ' + question.id);
      errorMessage.push('*Agent:* ' + navigator.userAgent);
      errorMessage.push('*getQuestion:* ' + e.message);

      logger.error(errorMessage.join('\n'));
    }
  }, []);

  const handleClickNext = useCallback(() => {
    if (hasNext()) {
      try {
        handleGoNext();
      } catch (e) {
        notify('Ops, ocorreu um erro. Tente novamente mais tarde!');
        logger.error(navigator.userAgent + ' - handleClickNext goNext: ' + e.message);
      }
    } else {
      if (!loading) {
        setLoading(true);
        handleFinish()
          .then(() => setLoading(false))
          .catch((error) => {
            setLoading(false);
            notify('Ops, ocorreu um erro. Tente novamente mais tarde!');

            const errorMessage = [];
            errorMessage.push('*Question:* ' + question.id);
            errorMessage.push('*Agent:* ' + navigator.userAgent);

            if (error.message === 'No local storage!') {
              const urlToRedirect = new URL(window.location.origin + '/new');
              window.location.href = urlToRedirect.href;
            }
            if (error.response) {
              errorMessage.push('*handleClickNext Finish:* ' + error.response.status);
              errorMessage.push('*Message:* ' + error.response.data?.message);
            } else if (error.request) {
              errorMessage.push('*handleClickNext Finish:* No response received!');
              errorMessage.push('*Timeout:* ' + error.request.timeout);
            } else {
              errorMessage.push('*handleClickNext Finish:* ' + error.message);
            }

            logger.error(errorMessage.join('\n'));
          });
      }
    }
  }, [handleFinish, handleGoNext, hasNext, loading, notify, question.id]);

  const handleCloseModal = useCallback(() => {
    sendAmplitudeEvent('closeModal', undefined, {
      question: currentQuestionId,
      modalTime: Date.now() - startTime,
    });
    setShowModal(false);
  }, [startTime, currentQuestionId]);

  const handleOpenModal = useCallback(() => {
    setStartTime(Date.now());
    sendAmplitudeEvent('openModal', undefined, { question: currentQuestionId });
    setShowModal(true);
  }, [currentQuestionId]);

  const handleOpenZendesk = useCallback(() => {
    const zendesk = document.querySelectorAll('div[role="presentation"]')[0];

    const hideZendesk = () => {
      window.zE?.('messenger:set', 'zIndex', -1);
      window.zE?.('messenger', 'close');
      if (zendesk) zendesk.style.display = 'none';
      document.removeEventListener('mousedown', hideZendesk);
    };

    window.zE?.('messenger:set', 'zIndex', 1);
    window.zE?.('messenger', 'open');
    if (zendesk) zendesk.style.display = 'block';

    document.addEventListener('mousedown', hideZendesk);
  }, []);

  const answerWrapStyle =
    question?.answers?.length < 5 ? { display: 'flex', alignItems: 'center' } : null;
  const normalQuestionTypes = ['formula_name', 'user_contact_info', 'ingredients'];

  return (
    <>
      <div
        className={[
          'form-wrap',
          question?.answers?.length > 4 ? 'many-answers' : null,
          question?.answers?.length === 5 ? 'five-answers' : null,
          question?.answers?.length === 6 ? 'six-answers' : null,
          question?.answers?.length === 7 ? 'seven-answers' : null,
          question?.answers?.length === 8 ? 'eight-answers' : null,
          question?.answers?.length === 10 ? 'ten-answers' : null,
          normalQuestionTypes.find((type) => type === question?.type)
            ? 'many-answers'
            : null,
          question?.type === 'formula_name' ? 'formula-name' : null,
          question?.type === 'user_contact_info' ? 'user-email' : null,
        ]
          .filter((x) => x)
          .join(' ')}
      >
        {normalQuestionTypes.find((item) => item === question?.type) ? (
          getQuestion(question)
        ) : (
          <>
            <Header />
            <div className="title-wrap">
              {hasPrev() && (
                <button
                  className="hide-on-mobile"
                  onClick={handleGoPrev}
                  aria-label="Questão Anterior"
                >
                  <IconArrowLeft title="Voltar" color="#90969A" size={{ height: 20 }} />
                </button>
              )}
              <p className="question-title">{question?.title}</p>
            </div>
            <div className="question-wrap">
              <div className="answers-wrap" style={answerWrapStyle}>
                {getQuestion(question)}
              </div>
            </div>
          </>
        )}

        <div className="footer-actions">
          <div className="footer-side-actions"></div>

          <div className="footer-center-actions">
            <PrimaryButton
              ref={buttonNext}
              data-gtm={`NEXT::${formId}::${currentQuestionId}::${question.type}`}
              onClick={handleClickNext}
            >
              {!loading ? <div className="button-text">Próximo!</div> : <LoadingDots />}
            </PrimaryButton>
            <AnswersProgress className="hide-on-mobile" />
          </div>

          <div className="footer-side-actions">
            <IconButton
              onClick={handleOpenModal}
              pageTime={pageTime}
              aria-label="Descrição das opções"
            >
              <IconHelp size={{ height: 20 }} color="#90969A" />
            </IconButton>

            <AnswersProgress className="hide-on-desktop" />

            <IconButton
              onClick={handleOpenZendesk}
              pageTime={pageTime}
              aria-label="Pedir ajuda em tempo real"
            >
              <IconMessage size={{ height: 20 }} color="#90969A" />
            </IconButton>
          </div>
        </div>
      </div>

      <DescriptionModal
        show={showModal}
        onClose={handleCloseModal}
        description={question?.description}
      />
    </>
  );
}

export default Question;
