import React, { memo } from 'react';
import { IconArrowLeft, IconLogoJust } from '../customIcons';
import { useAppContext } from '../../context/AppContext';
import './styles.css';

function HeaderWithContent({ children, addAction, ...props }) {
  const { hasPrev, handleGoPrev } = useAppContext();

  return (
    <div className="header-with-content-wrap" {...props}>
      {hasPrev() && (
        <button onClick={handleGoPrev}>
          <IconArrowLeft title="Voltar" color="#90969a" size={{height: 24}} />
        </button>
      )}

      <div>
        <IconLogoJust color="#EC8063" size={{width: 115}} title="Just For You Logo"/>
      </div>
      {children}
    </div>
  );
}

export default memo(HeaderWithContent);