import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useFormContext } from '../context/FormContext';

function CustomRoute({ isPrivate = false, component: Component, ...rest }) {
  const { formId } = useFormContext();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (
          (location.pathname === '/' && !formId) ||
          (['/form', '/gift', 'club'].includes(location.pathname) && formId)
        ) {
          return <Component />;
        } else {
          return (
            <Redirect
              to={{
                pathname: formId ? `/form` : '/',
                search: location.search,
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default CustomRoute;
