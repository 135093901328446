import React, { useRef, useState, useEffect } from 'react';
import { IconArrowLeft } from '../customIcons';
import { sendAmplitudeEvent } from '../../analytics';
import step1_FillingInForm from '../../assets/tutorial/step1-filling-in-form.svg';
import step2_ShampooAndIngredients from '../../assets/tutorial/step2-shampoo-and-ingredients.svg';
import step3_KitBox from '../../assets/tutorial/step3-kit-box.svg';

import './styles.css';

const supportsIntersectionObserver = 'IntersectionObserver' in window;

function Carousel(props) {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const refs = useRef([]);
  const amplitudeEventSent = useRef(false);
  const addNode = React.useCallback((node) => refs.current.push(node), []);
  const observer = useRef(null);

  useEffect(() => {
    if (supportsIntersectionObserver) {
      if (observer.current) observer.current.disconnect();

      const observerHandler = (entries, observer) => {
        for (const entry of entries) {
          if (entry.intersectionRatio >= 0.5) {
            setCarouselIndex(refs.current.findIndex((ref) => ref === entry.target));
          }
        }
      };

      const getObserver = (ref) => {
        if (ref.current !== null) return ref.current;

        let newObserver = new IntersectionObserver(observerHandler, {
          root: null,
          rootMargin: '0px',
          threshold: 0.5,
        });

        ref.current = newObserver;

        return newObserver;
      };

      const newObserver = getObserver(observer);
      refs.current.forEach((node) => newObserver.observe(node));

      return () => newObserver.disconnect();
    }
  }, []);

  if ((!amplitudeEventSent.current) && (carouselIndex !== 0)) {
    sendAmplitudeEvent('carouselScroll');
    amplitudeEventSent.current = true;
  }

  const scrollToCarrouselItem = (itemIndex) => {
    if (!supportsIntersectionObserver) setCarouselIndex(itemIndex);
    refs.current[itemIndex].parentNode.scrollLeft = refs.current[itemIndex].offsetLeft;
  };

  return (
    <div className={props.className}>
      <div className="carousel">
        <div className="carousel-side-buttons">
          <div
            className={`carousel-left-button-wrap${carouselIndex === 0 ? ' inactive-side-button' : ''}`}
            onClick={() => scrollToCarrouselItem(carouselIndex - 1)}
          >
            <button className="carousel-left-button">
              <IconArrowLeft title="Voltar" color="#90969A" size={{ height: 20, width: 20 }} />
            </button>
          </div>
          <div
            className={`carousel-right-button-wrap${carouselIndex === 2 ? ' inactive-side-button' : ''}`}
            onClick={() => scrollToCarrouselItem(carouselIndex + 1)}
          >
            <button className="carousel-right-button">
              <IconArrowLeft title="Avançar" color="#90969A" size={{ height: 20, width: 20 }} />
            </button>
          </div>
        </div>
        <div
          className="carousel-content"
          style={{ overflowX: supportsIntersectionObserver ? 'auto' : 'hidden' }}
        >
          <div className="carousel-item" ref={addNode}>
            <p className="carousel-step-number">1°</p>
            <img src={step1_FillingInForm} alt="Formulário para gereção da forma sendo preenchido"/>
            <p className="carousel-text">Conte sobre seus <b>fios</b> e suas <b>necessidades</b></p>
          </div>
          <div className="carousel-item" ref={addNode}>
            <p className="carousel-step-number">2°</p>
            <img src={step2_ShampooAndIngredients} alt="Shampoo JustForYou com ingredientes ao redor"/>
            <p className="carousel-text">Veja os <b>ingredientes</b> ideais para você!</p>
          </div>
          <div className="carousel-item" ref={addNode}>
            <p className="carousel-step-number">3°</p>
            <img src={step3_KitBox} alt="Caixa do kit de shampoo e condicionador"/>
            <p className="carousel-text">Finalize e receba seu <b>KIT personalizado</b> em casa!</p>
          </div>
        </div>
        <div className="carousel-steps-indicator">
          <button
            className={`carousel-button${carouselIndex === 0 ? ' active-button' : ''}`}
            onClick={() => scrollToCarrouselItem(0)}
          ></button>
          <button
            className={`carousel-button${carouselIndex === 1 ? ' active-button' : ''}`}
            onClick={() => scrollToCarrouselItem(1)}
          ></button>
          <button
            className={`carousel-button${carouselIndex === 2 ? ' active-button' : ''}`}
            onClick={() => scrollToCarrouselItem(2)}
          ></button>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
