import React, { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { useFormContext } from '../../context/FormContext';
import Answer from '../Answer';
import './styles.css';
import './styles';

function MultiChoiceAnswers({ question, maxAnswers }) {
  const { setAnswer, answers, notify, currentQuestionId } = useAppContext();
  const [noneId, setNoneId] = useState(null);
  const { formId } = useFormContext();

  useEffect(() => {
    const noneAnswer = question?.answers.find((answer) => answer.none);
    setNoneId(noneAnswer?.id || null);
  }, [question]);

  function isActive(id) {
    const formQuestion = answers?.find((item) => item?.id === question?.id);
    if (formQuestion) {
      return formQuestion.answer?.find((item) => item === id);
    }

    return false;
  }

  const handleClick = useCallback(
    (id) => {
      if (
        answers.findIndex(
          (item) =>
            Array.isArray(item.answer) &&
            item.id === question.id &&
            item.answer.find((item) => item === id)
        ) !== -1
      ) {
        setAnswer(
          question.id,
          answers
            .find(
              (item) =>
                Array.isArray(item.answer) &&
                item.id === question.id &&
                item.answer.find((item) => item === id)
            )
            .answer.filter((item) => item !== id)
        );
        return;
      }

      if (id === noneId) {
        setAnswer(question.id, [id]);
        return;
      }

      if (
        (answers.find((item) => item.id === question.id)?.answer || []).length <
          maxAnswers ||
        maxAnswers === null
      ) {
        setAnswer(
          question.id,
          [...(answers.find((item) => item.id === question.id)?.answer || []), id].filter(
            (item) => item !== noneId
          )
        );
        return;
      }

      notify(`Ops, você já selecionou ${maxAnswers} opções`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxAnswers, answers, noneId, question, setAnswer]
  );

  return (
    <div
      className="multi-choice-answer-wrap"
      style={{
        gridColumnGap: question?.answers.length > 4 ? '0' : '16px',
      }}
    >
      {question?.answers.map(({ id, text, img }) => (
        <Answer
          key={id}
          text={text}
          image={img}
          isActive={isActive(id)}
          onClick={() => handleClick(id)}
          min={question.answers.length > 4}
          dataGtm={`${formId}::${currentQuestionId}::${text}`}
        />
      ))}
    </div>
  );
}

export default MultiChoiceAnswers;
