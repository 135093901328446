import React, { useCallback, useEffect, useState } from 'react';
import emojiRegex from 'emoji-regex';
import { REGEX_REMOVE_UNICODE_FORMULA } from '../../../constants';
import './styles.css';

function Input({ onChange, ...props }) {
  const [counter, setCounter] = useState(props?.value?.length || 0);
  const [color, setColor] = useState('#7EA896');

  useEffect(() => {
    if (counter <= 10) {
      setColor('#7EA896');
      return;
    }

    if (counter < 15) {
      setColor('#ff9a00');
      return;
    }

    setColor('#dc1c49');
  }, [counter]);

  const handleChange = useCallback(
    e => {
      if (e.target.value.length <= 15) {
        let formulaName = e.target.value
          .replace(emojiRegex(), '')
          .replace(REGEX_REMOVE_UNICODE_FORMULA, '');
        onChange && onChange(formulaName);
        setCounter(formulaName.length);
      }
    },
    [onChange],
  );

  return (
    <div className="input-container">
      <input
        type="text"
        onChange={handleChange}
        // maxLength={15}
        value={props.value}
        {...props}
      />
      <span style={{ color, borderColor: color }}>{counter}</span>
    </div>
  );
}

export default Input;
