import React, { memo, useCallback, useState } from 'react';
import Carousel from '../../components/Carousel';
import { IconLogoJust } from '../../components/customIcons';
import Header from '../../components/Header';
import LoadingDots from '../../components/LoadingDots';
import PrimaryButton from '../../components/PrimaryButton';
import { useAppContext } from '../../context/AppContext';
import { useFormContext } from '../../context/FormContext';
// import WomanFace from '../../assets/woman-face.png';
// import WomanFaceClub from '../../assets/woman-face-club.png';
// import WelcomeGift from '../../assets/welcome-gift.png';
import step1_FillingInForm from '../../assets/tutorial/step1-filling-in-form.svg';
import step2_ShampooAndIngredients from '../../assets/tutorial/step2-shampoo-and-ingredients.svg';
import step3_KitBox from '../../assets/tutorial/step3-kit-box.svg';

import './styles.css';

function Welcome() {
  const { setFormId, setCurrentQuestionId, notify } = useAppContext();
  const [loading, setLoading] = useState(false);
  const { createForm } = useFormContext();
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');

  const handleStart = useCallback(async () => {
    try {
      setLoading(true);
      const { questionId, formId } = await createForm(type || 'normal');
      setFormId(formId);
      setCurrentQuestionId(questionId);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        console.error(
          `Error status: ${error.response.status} \nMessage: ${error.response.data?.message}`
        );
      } else if (error.request) {
        console.error('No response received! Timeout: ' + error.request.timeout);
      } else {
        console.error(error);
      }
      setFormId(123);
      setCurrentQuestionId(1);
      setLoading(false);
      notify('Ops! ocurreu um erro, tente novamente mais tarde.');
    }
    /* eslint-disable-next-line */
  }, []);

  function getWelcomePage() {
    switch (type) {
      case 'normal':
        return (
          <div
            className="home-page-wrap"
            // style={{ backgroundImage: `url(${WomanFace})` }}
          >
            <div className="home-page-content">
              <Header />

              <p>
                Seja muitoaaaa bem vinda(o)! E aí, pronta(o) para fazer a fórmula ideal
                para o seu cabelo?
              </p>

              <PrimaryButton onClick={handleStart}>Começar!</PrimaryButton>
            </div>
          </div>
        );
      case 'club':
        return (
          <div
            className="home-page-wrap"
            // style={{ backgroundImage: `url(${WomanFaceClub})` }}
          >
            <div className="home-page-content">
              <Header />

              <p>Todo mês uma fórmula DIFERENTE e EXCLUSIVA para você!</p>

              <PrimaryButton onClick={handleStart}>Começar!</PrimaryButton>
            </div>
          </div>
        );
      case 'gift':
        return (
          <div
            className="home-page-wrap"
            // style={{ backgroundImage: `url(${WelcomeGift})` }}
          >
            <div className="home-page-content">
              <Header />

              <p>
                Presenteie quem você mais gosta fazendo uma fórmula personalizada para ela
                (e)!
              </p>

              <PrimaryButton onClick={handleStart}>Começar!</PrimaryButton>
            </div>
          </div>
        );

      default:
        return (
          <div className="home-page-wrap">
            <div className="home-page-content">
              <div className="welcome-header-wrap">
                <div className="homa-page-logo">
                  <IconLogoJust
                    color="#EC8063"
                    size={{ width: 115 }}
                    title="Just For You Logo"
                  />
                </div>
                <h1 className="home-page-title">Personalize seu tratamento de cabelo!</h1>
                <p className="home-page-highlight">Olha como é fácil:</p>
              </div>

              {/* Carousel is only displayed on mobile */}
              <Carousel className="home-page-carousel" />

              <div className="home-page-tutorial">
                <div className="tutorial-wrap">
                  <div className="tutorial-item">
                    <p className="tutorial-step-number">1°</p>
                    <img
                      src={step1_FillingInForm}
                      alt="Formulário para gereção da forma sendo preenchido"
                    />
                    <p className="tutorial-text">
                      Conte sobre seus <b>fios</b> e suas <b>necessidades</b>
                    </p>
                  </div>
                  <div className="tutorial-item">
                    <p className="tutorial-step-number">2°</p>
                    <img
                      src={step2_ShampooAndIngredients}
                      alt="Shampoo JustForYou com ingredientes ao redor"
                    />
                    <p className="tutorial-text">
                      Veja os <b>ingredientes</b> ideais para você!
                    </p>
                  </div>
                  <div className="tutorial-item">
                    <p className="tutorial-step-number">3°</p>
                    <img
                      src={step3_KitBox}
                      alt="Caixa do kit de shampoo e condicionador"
                    />
                    <p className="tutorial-text">
                      Finalize e receba seu <b>KIT personalizado</b> em casa!
                    </p>
                  </div>
                </div>
              </div>

              <div className="home-page-footer">
                <PrimaryButton onClick={!loading ? handleStart : () => {}}>
                  {!loading ? (
                    <div className="button-text">Começar!</div>
                  ) : (
                    <LoadingDots />
                  )}
                </PrimaryButton>
              </div>
            </div>
          </div>
        );
    }
  }

  return getWelcomePage();
}

export default memo(Welcome);
