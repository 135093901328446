import React, { useCallback } from 'react';
import HeaderWithContent from '../../components/HeaderWithContent';
import { useAppContext } from '../../context/AppContext';

function GiftUserName({ question }) {
  const { setAnswer, answers } = useAppContext();

  const handleChange = useCallback(
    (field, value) => {
      setAnswer(question.id, {
        ...(answers.find(item => item.id === question.id).answer || {}),
        [field]: value,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [answers, question.id],
  );
  return (
    <HeaderWithContent>
      <div className="gift-step2-wrap">
        <label htmlFor="name">
          Seu nome*
          <input
            type="text"
            id="name"
            placeholder="Digite seu nome aqui..."
            onChange={e => handleChange('name', e.target.value)}
            value={
              answers.find(item => item.id === question.id).answer?.name || ''
            }
          />
        </label>

        <label htmlFor="receiverName">
          Quem vai receber o presente?*
          <input
            type="text"
            id="receiverName"
            placeholder="Digite o nome aqui..."
            onChange={e => handleChange('receiverName', e.target.value)}
            value={
              answers.find(item => item.id === question.id).answer?.receiverName || ''
            }
          />
        </label>

        <span>*campos obrigatórios</span>
      </div>
    </HeaderWithContent>
  );
}

export default GiftUserName;
