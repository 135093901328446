import React, { memo, useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Icon } from '../customIcons';
import Modal from '../Modal';
import './styles.css';
import './styles';

function DescriptionModal({ description, show, onClose }) {
  const { answers } = useAppContext();
  const [firstName, setFirstName] = useState('');
  const [classNames, setClassNames] = useState('');
  const [descriptions, setDescriptions] = useState([]);

  useEffect(() => {
    setFirstName(
      answers.find((answer) => answer.type === 'user_email' || answer.type === 'user_contact_info')?.answer
        ?.name || ''
    );
  }, [answers]);

  useEffect(() => {
    if (description?.description?.some((item) => item.img && item.title)) {
      setDescriptions(description.description.filter((item) => item.img && item.title));
      return;
    }

    if (description?.description?.every((item) => !item.img)) {
      setDescriptions(description.description);
      setClassNames(`only-text ${description?.description?.length >= 2 ? 'two-items' : ''}`);
      return;
    }

    setDescriptions(description.description);
    setClassNames('only-text' + (description?.title ? '' : ' no-header'));
  }, [description]);

  return (
    <Modal show={show} onClose={onClose} className="description-modal-content" bodyClassName={classNames}>
      {description?.title && <h1>{description?.title}</h1>}

      <div className="description-itens">
        {descriptions.map((item) => (
          <div
            key={item.title}
            className={[
              'description-item',
              !item.img && !item.title && description?.description?.length > 2 ? 'empty' : '',
              !item.img && description?.description?.length <= 2 ? 'text' : '',
            ]
              .filter((item) => item)
              .join(' ')}
          >
            <div className="description-content">
              {item.img !== '' ? <Icon title={item.img} className="icons-color" name={item.title} /> : false}
              {item.title && <h2>{item.title}</h2>}
              <p
                style={{ padding: !item.img ? '10px' : false }}
                dangerouslySetInnerHTML={{ __html: item.text.replace('$USER_NAME', firstName) }}
              />
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default memo(DescriptionModal);
