const telegramBot = {};

const TELEGRAM_LOG = process.env.REACT_APP_TELEGRAM_LOG?.toLowerCase?.() === 'true';

telegramBot.sendMessage = (...messages) => {
  if (!TELEGRAM_LOG || !process.env.REACT_APP_TELEGRAM_TOKEN) return;

  let messagesFormated = '';

  messages[0] = messages[0].replace('[JFY] ', '');

  messages.forEach((msg) => {
    if (msg === null) return;

    if (msg.constructor === Object) {
      msg = '<code>' + JSON.stringify(msg, null, 2) + '</code>';
    }
    messagesFormated += '\n' + msg;
  });

  fetch(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_TOKEN}/sendmessage`, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      chat_id: process.env.REACT_APP_TELEGRAM_CHAT,
      text: messagesFormated,
      parse_mode: 'HTML',
      disable_web_page_preview: true,
    }),
  })
    .then(async (response) => {
      if (response.status !== 200) {
        console.error(
          `Error trying to send message to telegram: ${response.status} - ${
            response.statusText
          }\n${await response.text()}`
        );
      }
    })
    .catch((error) => console.error(`Error trying to send message to telegram\n${error}`));
};

export default telegramBot;
