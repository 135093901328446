import React, { memo } from 'react';
import { useAppContext } from '../../context/AppContext';
import './styles.css';

function AnswersProgress({ className }) {
  const { hasNext, hasPrev, currentQuestionId, questions } = useAppContext();
  return (
    <div className={`answers-progress-wrap ${className}`}>
      <ul className="points">
        <li className={hasNext() && !hasPrev() ? 'active' : null}></li>
        <li className={hasNext() && hasPrev() ? 'active' : null} />
        <li className={!hasNext() && hasPrev() ? 'active' : null} />
      </ul>

      <span>
        {questions.findIndex((question) => question.id === currentQuestionId) + 1}/{questions.length}
      </span>
    </div>
  );
}

export default memo(AnswersProgress);
