import React, { useCallback } from 'react';
import HeaderWithContent from '../../components/HeaderWithContent';
import { useAppContext } from '../../context/AppContext';
import '../../components/UserEmail/styles.css'

function GiftUserEmail({ question }) {
  const { setAnswer, answers } = useAppContext();

  const handleChange = useCallback(
    (field, value) => {
      setAnswer(question.id, {
        ...(answers.find(item => item.id === question.id).answer || {}),
        [field]: value,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [answers, question.id],
  );
  return (
    <HeaderWithContent>
      <div className="user-email-wrap">
      <label htmlFor="email">
          Seu e-mail*
          <input
            type="email"
            id="email"
            placeholder="Digite seu e-mail aqui..."
            onChange={e => handleChange('email', e.target.value)}
            value={
              answers.find(item => item.id === question.id).answer?.email || ''
            }
          />
        </label>

        <label htmlFor="phone">
          Quer descontos exclusivos no seu whatsapp?
          <input
            type="text"
            id="phone"
            placeholder="Digite seu celular aqui..."
            disabled={
              answers.find(item => item.id === question.id).answer?.dont_want ||
              false
            }
            onChange={e => handleChange('phone', e.target.value)}
            value={
              answers.find(item => item.id === question.id).answer?.phone || ''
            }
          />
          <label className="container">
            Não, obrigada (o)
            <input
              type="checkbox"
              onChange={e => handleChange('dont_want', e.target.checked)}
              checked={answers.find(item => item.id === question.id).answer
                ?.dont_want || false}
              // value={
              //   answers.find(item => item.id === question.id).answer
              //     ?.dont_want || false
              // }
            />
            <span className="checkmark"></span>
          </label>
        </label>

        <span>*campos obrigatórios</span>
      </div>
    </HeaderWithContent>


  );
}

export default GiftUserEmail;
