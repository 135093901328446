import React from 'react';
import IconsPath from './iconsPath';

const createSvg = ({ color, size, className, title, name }, pathsToDraw, viewBox) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox ? viewBox : '0 0 24 24'}
    width={typeof size === 'object' ? size?.width : size}
    height={typeof size === 'object' ? size?.height : size}
    className={className}
    aria-labelledby="titleIcon"
    aria-hidden="true"
    focusable="false"
    role="img"
  >
    <title id="titleIcon">{ name || title }</title>
    {pathsToDraw &&
      pathsToDraw.map((path, index) => (
      <path key={index} d={path} fill={color} />
      ))
    }
  </svg>
);

export const IconCloseSquareOutline = (attributes) => createSvg(attributes, IconsPath.closeSquareOutline, '0 0 116.41 97.92');
export const IconAdd = (attributes) => createSvg(attributes, IconsPath.add);
export const IconRemove = (attributes) => createSvg(attributes, IconsPath.remove);
export const IconExclamationTriangle = (attributes) => createSvg(attributes, IconsPath.exclamationTriangle, '0 0 576 512');
export const IconCart = (attributes) => createSvg(attributes, IconsPath.cart, '0 0 42 42');
export const IconLogoJust = (attributes) => createSvg(attributes, IconsPath.logoJust, '0 0 300 141');
export const IconMessage = (attributes) => createSvg(attributes, IconsPath.message);
export const IconHelp = (attributes) => createSvg(attributes, IconsPath.help);
export const IconArrowLeft = (attributes) => createSvg(attributes, IconsPath.arrowLeft);
export const Icon = (attributes) => createSvg(attributes, IconsPath[attributes.title], '0 0 145.133 125.008');
