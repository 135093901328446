import React, { memo } from 'react';
import Lottie from 'lottie-react-web';
import loadingJson from '../../assets/loading-animation.json';

import './styles.css';

function Loading() {
  return (
    <div className="loading-wrap">
      <div className="loading-content" />

      <p>Aguarde um segundo... <br /> Jul.IA está gerando sua fórmula especial</p>
      <Lottie
        options={{
          animationData: loadingJson,
        }}
      />
    </div>
  );
}

export default memo(Loading);
