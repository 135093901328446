import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CustomRoute from './CustomRoute';
import NewForm from '../pages/NewForm';
import Welcome from '../pages/Welcome';
import FormPage from '../pages/Form';
import GiftPage from '../pages/Gift';
import ClubPage from '../pages/Club';

function Routes() {
  return (
    <Switch>
      <Route exact path="/new" component={NewForm} />
      <CustomRoute path="/" exact component={Welcome} />
      <CustomRoute path="/form" component={FormPage} />
      <CustomRoute path="/gift" component={GiftPage} />
      <CustomRoute path="/club" component={ClubPage} />
    </Switch>
  );
}

export default Routes;
