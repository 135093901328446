import { CAMPAIGNS } from '../constants';
import { getLocalStorage, setLocalStorage } from '../context/FormContext';
import api from '../services/api';

export const checkExistentCart = () => {
  const localStorageData = getLocalStorage();
  if (localStorageData?.checkout) {
    const checkoutLinkLocal = localStorageData.checkout;

    if (!checkoutLinkLocal.url && !checkoutLinkLocal.hash) {
      console.error('Localstorage invalid');
    }

    api
      .get('/checkout/cart/' + checkoutLinkLocal.cartId)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.valid === false) {
            if (data.reason === 'abandoned' && data.cloned_id && data.cloned_url) {
              setLocalStorage({
                checkout: { url: data.cloned_url, cartId: data.cloned_id },
                ...localStorageData,
              });
            } else if (data.reason === 'finished') {
              delete localStorageData.checkout;
              setLocalStorage(localStorageData);
              window.location.href = '/new';
            }
          }
        } else {
          console.error(data);
        }
      })
      .catch(console.error);
  }
};

export const checkCampaign = () => {
  const localStorageData = getLocalStorage();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const campaignUrlParam =
    urlSearchParams.get('campaign') || urlSearchParams.get('CAMPAIGN');
  const campaignHash = urlSearchParams.get('cpmhash');

  if (campaignHash) {
    setLocalStorage({
      ...localStorageData,
      checkout: {
        ...localStorageData.checkout,
        hash: campaignHash,
      },
    });
    // When user access /new the localStorage must be cleaned
    // So who clean have to clean it right, keeping the hashcode if necessary
    if (window.location.pathname !== '/new') {
      urlSearchParams.delete('cpmhash');
      let paramsString = urlSearchParams.toString();
      if (paramsString !== '') paramsString = '?' + paramsString;
      window.location.search = paramsString;
    }
  }

  if (CAMPAIGNS[campaignUrlParam]) {
    if (
      urlSearchParams.get('products') !==
      CAMPAIGNS[campaignUrlParam].products.join(',').trim()
    ) {
      window.location.href = `${window.location.origin}/?campaign=${campaignUrlParam}&products=${CAMPAIGNS[campaignUrlParam].products}`;
    }
  }
};
