import { createContext, useCallback, useContext, useState } from 'react';
import { sendAmplitudeEvent, sendGtmEvent } from '../analytics';
import {
  BETA_CHECKOUT_PRODUCTS,
  CAMPAIGNS,
  JFY_CHECKOUT_PRODUCTS,
  LOCAL_STORAGE_KEY,
} from '../constants';
import api from '../services/api';
import { useAppContext } from './AppContext';
import { giftEndQuestions, giftQuestions, normalQuestions } from './questions';

// Local Storage Manager
export function getLocalStorage(formOnly = false) {
  const localStorageData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
  return formOnly ? localStorageData?.form || {} : localStorageData;
}

export function setLocalStorage(localStorageData) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localStorageData));
}

export function clearLocalStorage() {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
}

// Context
const FormContext = createContext(null);

//prevent XSS
function regexUrl(url) {
  url = url.replace(/</g, '&lt;');
  url = url.replace(/>/g, '&gt;');
  return url;
}

// Get callback url from url
function getUrlCallback() {
  const params = new URLSearchParams(window.location.search);
  const urlCallback = params.get('callback');

  if (urlCallback) {
    return urlCallback;
  }

  return false;
}

// Verify if url has justfor.com.br as hostname
function verifyUrlCallback(urlUnverified, formId) {
  let url = new URL(urlUnverified);
  if (url) {
    if (url.hostname.includes('justfor.com.br')) {
      //replace {{formId}} with formId
      const urlReplaced = decodeURI(url).replace('{{formId}}', formId);

      return urlReplaced;
    }
  }
  return false;
}

export function FormProvider({ children }) {
  const { loadLastLocalForm } = useAppContext();

  const localStorageData = getLocalStorage();
  const [formId, setFormId] = useState(localStorageData?.form?.formId || null);
  const [creationToken, setCreationToken] = useState(
    localStorageData?.form?.creationToken || null
  );

  const createForm = useCallback(async (type) => {
    const createPayload = { type };

    const urlSearchParams = new URLSearchParams(window.location.search);
    let urlUtms = {};

    if (urlSearchParams.has('utm_term'))
      urlUtms.utm_term = urlSearchParams.get('utm_term');
    if (urlSearchParams.has('utm_content'))
      urlUtms.utm_content = urlSearchParams.get('utm_content');
    if (urlSearchParams.has('utm_source'))
      urlUtms.utm_source = urlSearchParams.get('utm_source');
    if (urlSearchParams.has('utm_medium'))
      urlUtms.utm_medium = urlSearchParams.get('utm_medium');
    if (urlSearchParams.has('utm_campaign'))
      urlUtms.utm_campaign = urlSearchParams.get('utm_campaign');

    // Sends utm parameters if url contains them
    // Reason: save database storage
    if (
      urlUtms.utm_source ||
      urlUtms.utm_medium ||
      urlUtms.utm_campaign ||
      urlUtms.utm_term ||
      urlUtms.utm_content
    ) {
      createPayload.utms = urlUtms;
    }

    const response = await api.post('/form/submissions/create', createPayload);

    const { _id: formId, questionnaire, creationToken } = response.data;

    let concatenedQuestions = [];
    if (type === 'gift') {
      concatenedQuestions = [
        ...giftQuestions,
        ...questionnaire.questions,
        ...giftEndQuestions,
      ];
    } else {
      concatenedQuestions = [...questionnaire.questions, ...normalQuestions];
    }

    const newLocalStorageData = {
      form: {
        formType: type,
        formId,
        creationToken,
        questions: concatenedQuestions,
        products: questionnaire.products,
        answers: concatenedQuestions.map((question) => ({
          id: question.id,
          answer: question.type === 'multi' ? [] : null,
          type: question.type,
        })),
      },
    };

    if (localStorageData?.checkout) {
      newLocalStorageData.checkout = localStorageData.checkout;
    }
    setLocalStorage(newLocalStorageData);

    setFormId(formId);
    setCreationToken(creationToken);
    loadLastLocalForm();

    let dataLayerProducts = { currency: 'BRL', value: 0, items: [] };
    urlSearchParams
      .get('products')
      ?.split(',')
      .forEach((product) => {
        let productMatch = newLocalStorageData.form.products.find(
          (localStorageProduct) => localStorageProduct.name === product
        );
        if (productMatch) {
          const index = dataLayerProducts.items.findIndex(
            (item) => item.item_id === JFY_CHECKOUT_PRODUCTS[product].id
          );
          let dataLayerProduct = {
            item_id: JFY_CHECKOUT_PRODUCTS[product].id,
            item_name: productMatch.displayName,
            price: JFY_CHECKOUT_PRODUCTS[product].price,
            quantity: 1,
          };
          if (index === -1) {
            dataLayerProducts.items.push(dataLayerProduct);
          } else {
            dataLayerProducts.items[index].quantity += 1;
          }
          dataLayerProducts.value += dataLayerProduct.price;
        }
      });

    if (dataLayerProducts.items.length > 0) {
      sendGtmEvent('view_item', { ecommerce: dataLayerProducts });
    }

    return {
      formId,
      questionId: concatenedQuestions[0].id,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinish = useCallback(async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const localStorageData = getLocalStorage();
        if (!localStorageData?.form.answers) {
          return reject(new Error('No answers in local storage!'));
        }

        const urlSearchParams = new URLSearchParams(window.location.search);

        // Bypass checkout
        const campaignUrlParam =
          urlSearchParams.get('campaign') || urlSearchParams.get('CAMPAIGN');
        if (CAMPAIGNS[campaignUrlParam]) {
          sendAmplitudeEvent('finish', undefined, {});
          clearLocalStorage();

          const urlProducts = CAMPAIGNS[campaignUrlParam].products.map(
            (product) => BETA_CHECKOUT_PRODUCTS[product.trim()] + ':1'
          );
          const betaCheckoutUrl = `https://form.pay.yampi.com.br/r/${urlProducts}?hideBillet=true&hideCard=true&hidePix=true&showDeposit=true&promocode=${CAMPAIGNS[campaignUrlParam].promocode}`;

          window.location.href = betaCheckoutUrl;
          return resolve(betaCheckoutUrl);
        }

        const checkoutLink = {
          url: undefined,
          cartId: '',
        };

        if (localStorageData?.checkout && !localStorageData?.checkout.hash) {
          const checkoutLinkLocal = localStorageData.checkout;

          if (!checkoutLinkLocal.url) {
            reject(new Error('Localstorage invalid'));
          }

          checkoutLink.url = new URL(checkoutLinkLocal.url);
          checkoutLink.cartId = checkoutLinkLocal.cartId;
        } else {
          const userPersonalInfo = localStorageData.form.answers.find(
            (answer) =>
              answer.type === 'user_contact_info' || answer.type === 'user_email'
          ).answer;

          const createCheckoutUrlResponse = await api.post('/checkout/', {
            type: localStorageData.form.formType,
            name: userPersonalInfo.name.trim(),
            email: userPersonalInfo.email.toLowerCase(),
            utms: {
              source: urlSearchParams.get('utm_source'),
              medium: urlSearchParams.get('utm_medium'),
              campaign: urlSearchParams.get('utm_campaign'),
              term: urlSearchParams.get('utm_term'),
              content: urlSearchParams.get('utm_content'),
            },
            submissions: [
              {
                submissionId: localStorageData.form.formId,
                creationToken: localStorageData.form.creationToken,
                products: localStorageData.form.products.map((product) => ({
                  ...product,
                  quantity: 1,
                })),
              },
            ],
            jfyCheckout: true,
          });

          if (createCheckoutUrlResponse.status >= 200) {
            if (
              !createCheckoutUrlResponse.data.url ||
              !createCheckoutUrlResponse.data.cartId
            ) {
              return reject(new Error('Backend returned an invalid Checkout URL'));
            }

            function urlCallback() {
              const urlContent = getUrlCallback();
              if (!urlContent) return false;
              const urlVerified = verifyUrlCallback(urlContent, formId);
              if (!urlVerified) return false;

              return urlVerified;
            }

            if (urlCallback()) {
              const urlReplaced = regexUrl(urlCallback());
              checkoutLink.url = new URL(urlReplaced);
            } else {
              checkoutLink.url = new URL(createCheckoutUrlResponse.data.url);
            }
            checkoutLink.cartId = createCheckoutUrlResponse.data.cartId;
          } else {
            reject(createCheckoutUrlResponse.status);
          }
        }

        if (urlSearchParams.has('promocode')) {
          checkoutLink.url.searchParams.set(
            'promocode',
            urlSearchParams.get('promocode')
          );
        }

        if (urlSearchParams.has('products')) {
          checkoutLink.url.searchParams.set('products', urlSearchParams.get('products'));
        }

        if (urlSearchParams.has('utm_source')) {
          checkoutLink.url.searchParams.set(
            'utm_source',
            urlSearchParams.get('utm_source')
          );
        }

        if (urlSearchParams.has('utm_medium')) {
          checkoutLink.url.searchParams.set(
            'utm_medium',
            urlSearchParams.get('utm_medium')
          );
        }

        if (urlSearchParams.has('utm_campaign')) {
          checkoutLink.url.searchParams.set(
            'utm_campaign',
            urlSearchParams.get('utm_campaign')
          );
        }

        if (urlSearchParams.has('utm_term')) {
          checkoutLink.url.searchParams.set('utm_term', urlSearchParams.get('utm_term'));
        }

        if (urlSearchParams.has('utm_content')) {
          checkoutLink.url.searchParams.set(
            'utm_content',
            urlSearchParams.get('utm_content')
          );
        }

        if (urlSearchParams.has('hidePix')) {
          checkoutLink.url.searchParams.set('hidePix', urlSearchParams.get('hidePix'));
        }

        if (urlSearchParams.has('hideCard')) {
          checkoutLink.url.searchParams.set('hideCard', urlSearchParams.get('hideCard'));
        }

        if (localStorageData?.checkout?.hash) {
          checkoutLink.url.searchParams.set('cpmhash', localStorageData.checkout.hash);
        }

        const formulaName = localStorageData.form.answers.find(
          (answer) => answer.type === 'formula_name'
        ).answer;

        checkoutLink.url.searchParams.set('form', localStorageData.form.formId);
        checkoutLink.url.searchParams.set('formulaName', formulaName.trim());

        setLocalStorage({
          checkout: { url: checkoutLink.url.href, cartId: checkoutLink.cartId },
          ...localStorageData,
        });

        sendAmplitudeEvent('finish', undefined, {});
        window.location.href = process.env.REACT_APP_URL_PAGE_ORDERS_MORE_INFO;
        resolve(checkoutLink.url.href);
      } catch (error) {
        reject(error);
      }
    });
  }, [formId]);

  return (
    <FormContext.Provider value={{ createForm, formId, creationToken, handleFinish }}>
      {children}
    </FormContext.Provider>
  );
}

export function useFormContext() {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('useFormContext must be used within an FormProvider');
  }

  return context;
}
