import React, { useCallback } from 'react';
import { useAppContext } from '../../context/AppContext';
import logger from '../../utils/logger';
import { IconArrowLeft } from '../../components/customIcons';
import Header from '../Header';
import InputMask from 'react-input-mask';

import './styles.css';

function UserEmail({ question }) {
  const { handleGoPrev, setAnswer, answers } = useAppContext();

  const handleChange = useCallback(
    (field, value) => {
      try {
        setAnswer(question.id, {
          ...(answers.find(item => item.id === question.id).answer || {}),
          [field]: value,
        });
      } catch (e) {
        const errorMessage = [];
        errorMessage.push('*' + field + ':* ' + value);
        errorMessage.push('*Agent:* ' + navigator.userAgent);
        errorMessage.push('*handleChange:* ' + e.message);

        logger.error(errorMessage.join('\n'));
      }
    },
    [answers, question.id, setAnswer],
  );

  return (
    <>
    <Header />
      <div className="user-email-wrap">
        <button className="hide-on-mobile" onClick={handleGoPrev}>
          <IconArrowLeft title="Voltar" color="#90969A" size={{height: 20}} />
        </button>
        <p className="email-page-title">{question?.title}</p>
        <label htmlFor="name">
          Seu nome*
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Digite seu nome aqui..."
            onChange={(e) => handleChange('name', e.target.value)}
            maxLength={100}
            value={answers.find((item) => item.id === question.id).answer?.name || ''}
          />
        </label>

        <label htmlFor="email">
          Seu e-mail*
          <input
            type="email"
            id="email"
            name="email"
            maxLength={254}
            placeholder="Digite seu e-mail aqui..."
            onChange={(e) => handleChange('email', e.target.value)}
            value={answers.find((item) => item.id === question.id).answer?.email || ''}
          />
        </label>

        <label htmlFor="phone">
          Quer descontos exclusivos no seu whatsapp?
          <InputMask
            mask="(99) 99999-9999"
            maskChar={null}
            name="phone"
            placeholder="Digite seu celular aqui..."
            value={answers.find((item) => item.id === question.id).answer?.phone || ''}
            onChange={(e) => handleChange('phone', e.target.value)}
            alwaysShowMask={false}
          />
          <label className="container">
            Não, obrigada(o)
            <input
              type="checkbox"
              onChange={(e) => handleChange('dont_want', e.target.checked)}
              checked={answers.find((item) => item.id === question.id).answer?.dont_want || false}
            />
            <span className="checkmark"></span>
          </label>
        </label>

        <span>*campos obrigatórios</span>
      </div>
    </>
  );
}

export default UserEmail;
