import React from 'react';
import './style.css';

const LoadingDots = () => {
  return (
    <div className="loading-animation-wrap">
      <ul>
        <li />
        <li />
        <li />
        <li />
      </ul>
    </div>
  );
};

export default LoadingDots;
