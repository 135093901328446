import telegramBot from './telegramBot';
import slackBot from './slackBot';

const logger = {};

/**
 * One or more functionalities are not working, preventing some
 * functionalities from working correctly.
 * @param {any} errors - Errors to show
 */
logger.error = (...errors) => {
  console.error('[JFY]', ...errors);
  telegramBot.sendMessage('<b>ERROR:</b>\n', ...errors);
  slackBot.sendMessage('ERROR\n', ...errors);
};

export default logger;
